import { useState } from "react"
import useFetch from "./useFetch"
import { authenticateUserWithPasswordMutation } from "../graphql/mutation/authenticateUserWithPasswordMutation"
import Cookies from "js-cookie"

const useLogin = () => {
  const [error, setError] = useState(null)

  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT

  const { data, loading, refetch } = useFetch(graphqlEndpoint, "POST")

  const login = async (idCard, password) => {
    try {
      const response = await refetch({
        data: authenticateUserWithPasswordMutation(idCard, password),
      })

      const result = response.data
      if (result.data.authenticateUserWithPassword.sessionToken) {
        const token = result.data.authenticateUserWithPassword.sessionToken
        Cookies.set("keystonejs-session", token, {
          path: "/",
          domain: "creditpro-keystone6-production.up.railway.app",
          // domain: "localhost",
        })

        const superAdminRole = result.data.authenticateUserWithPassword.item.isAdmin
        // localStorage.setItem("token", token)

        if (superAdminRole) {
          localStorage.setItem("isSuperAdmin", true)
        } else {
          localStorage.setItem("isSuperAdmin", false)
        }
        localStorage.setItem("isLogin", true)
        localStorage.setItem("isAdmin", true) // Update as per your logic

        if (result.data.authenticateUserWithPassword.item.showCMS) {
          localStorage.setItem("showCMS", true)
        } else {
          localStorage.removeItem("showCMS")
        }
        if (result.data.authenticateUserWithPassword.item.showEsignature) {
          localStorage.setItem("showEsignature", true)
        } else {
          localStorage.removeItem("showEsignature")
        }

        return {
          success: true,
          isAdmin: result.data.authenticateUserWithPassword.item.isAdmin,
          showCMS: result.data.authenticateUserWithPassword.item.showCMS,
          showEsignature: result.data.authenticateUserWithPassword.item.showEsignature,
        }
      } else {
        setError(result.data.authenticateUserWithPassword.message)
        return false
      }
    } catch (err) {
      setError(err.message)
      return false
    }
  }

  return { login, loading, error }
}

export default useLogin
